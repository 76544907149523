.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background:rgba(128,128,128,.6);
  z-index:5;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.stateSvg {
  height: 100%;
  position: absolute;
  right: 55%;
}
.App{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  overflow:hidden;
}
.builder-icon {
  height:auto;
}
.builderCard{
  top: 0;
  background-color: white;
  height: 45%;
  width:100%;
  margin-top: 10px;
  border-radius: 5px;
  position:relative;
  display:flex;

}

.builderCardInfo{
  color: black;
  width:65%;
  left:30%;
  position:absolute;
  top:53%;
  height:30%;
  overflow-y: scroll;
  font-size: 1.7vmin;
  text-align:left;
}
.builderCardTitle{
  position:absolute;
  top: 30%;
  font-size:2vmin;
  font-family:Lato;
  font-weight:100;
  color:gray;
  width:60%;
  left:30%;
  height:1em;
  display:flex;
}
@media screen and (max-width: 1030px) {
  .builderCardTitle{
    top: 25%;
  }
} 
@media screen and (max-width: 728px) {
  .builderCardTitle{
    top: 20%;
  }
} 

.cardLink {
  position:relative;
  font-size:1vmin;
  width: 10vmin;
  white-space:nowrap;
  
}
.builderCardImage {
  position:absolute;
  left:2.5%;
  top:50%;
  width: 25%;
  display:flex;
  transform: translateY(-50%);
}
.builderCardContainer{
  height:50%;
  position:relative;
  overflow-y: scroll;
  width:35%;
  top:25%;
  left:47%;
  padding:1%;
  border-radius: 5px;


}
.USAMap{
  position:relative;
  z-index:1;
  top:12vh;
  height:58vh;

}
.App footer {
  position:fixed;
  bottom:0;
  display:flex;
  flex-wrap: wrap;
  border-style: solid;
  border-width:1px;
  border-left:0px;
  border-right:0px;
  border-color: gray;
  width: 100%;
  justify-content: center;
  z-index:4;
}

.App footer img{
  height: 100px;
  padding: 1%;
  max-width: 200px;
}
@media screen and (max-width: 1625px) {
  .App footer img{
    height: 80px;
  }
} 
@media screen and (max-height: 1460px){
  .App footer img{
    height: 60px;
  }
}

@media screen and (max-width: 1210px) {
  .App footer img{
    height: 40px;
  }
} 
@media screen and (max-width: 865px) {
  .App footer img{
    height: 25px;
  }
} 
@media screen and (max-width: 320px) {
  .App footer img{
    height: 20px;
  }
} 
.builder_logo_title {
  width: 48px;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right:50vw;
}

@media screen and (min-width: 450px) {
  .builder_logo_title {
    width: 68px;
  }
}

@media screen and (min-width: 650px) {
  .builder_logo_title {
    width: 15vmin;

   }
}

h1{
  font-family: montserrat;
  font-size: 2vw;
  font-weight: 400;
  display:inline;
}
.title{
  width:45%;
  margin-left:30%;
  text-align:center;
  display:flex;
  position: relative;
  top:0;
}
.overlayInfo{
  height:100vh;
  width:100vw;
  background-color: rgba(0,0,0,0);
  position: absolute;
  z-index:2;
  display: flex;
  justify-content: center;
}
.info{
  width:65vw;
  text-align:left;
  position:absolute;
  top:4vw;
  height:15%;
  overflow:hidden;
  z-index:3;
}
.builderInfoDesc{
  text-align:left;
  font-size:1.2vh;
  position:relative;
  left: 16vw;
  overflow-y: scroll;
  height:7vh;
  width:35vw;

}
.builderInfoName{
  position:relative;
  left: 16vw;
  width: 45vw;
  font-size:2vh;
  font-family:lato;
  font-weight:100;
  color:gray;
}
/*p{
  text-align:left;
  font-size:14px;
}**/

h3{
  text-align:left;
  font-size:16px;

}

.builderLink{
  position:absolute;
  white-space: nowrap;
  top:0;
  text-align: center;
  font-size:1.5vmin;
  top:-3px;

}

a{
  margin-left:.8em;
  
  font-family:lato;
  background-color:#00649b;
  border-radius:5px;
  text-decoration:none;
  color:white;
  font-weight:5;
  height:1em;
  padding-left:7px;
  padding-bottom:5px;
  padding-right:7px;
  padding-top:3px;
}